<template>
    <div>
        <b-row align-h="center" v-if="state === 'initializing'">
            <b-spinner></b-spinner>
        </b-row>
        <b-row class="mb-4" v-if="state !== 'initializing'">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Checklists</h4>
                        </b-col>
                        <b-col class="text-right">
                            <b-button :variant="selectedChecklist.disabled === true ? 'red' : 'success'" size="sm" squared @click="selectedChecklist.disabled = !selectedChecklist.disabled">{{selectedChecklist.disabled === true ? 'Disabled' : 'Enabled'}}</b-button>
                            <b-button class="ml-2" variant="red" size="sm" squared @click="deleteCurrentChecklist()">Delete Checklist</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <label>Procedure Name</label>
                            <b-form-input v-model="selectedChecklist.description"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <label>Procedure Number</label>
                            <b-form-input v-model="selectedChecklist.procedureNumber"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <label>Checklist Type</label>
                            <b-form-select v-model="selectedChecklist.checkListTypeId" :disabled="state === 'loading'">
                                <b-form-select-option v-for="(item, index) in checklistTypes" :key="index" :value="item.id">{{item.description}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <hr class="mx-3" />
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="primary" squared class="mr-2" @click="openAddNewCategoryModal()">Add Category</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>

                                <template #cell(questions)="row">
                                    {{row.item.questions.length}}
                                </template>


                                <template #cell(imageFile)="row">
                                    <b-row align-v="center">
                                        <b-badge :variant="row.item.imageFile.fileName === null ? 'red' : 'green'">{{row.item.imageFile.fileName === null ? 'No' : 'Yes'}}</b-badge>
                                        <b-icon-eye @click="openModalImage(row.item.imageFile)" size="lg" v-if="row.item.imageFile.fileName !== null" class="ml-2"></b-icon-eye>
                                    </b-row>
                                </template>

                                <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end">
                                        <b-icon-eye @click="row.toggleDetails" size="lg" v-if="!row.detailsShowing"></b-icon-eye>
                                        <b-icon-eye-slash @click="row.toggleDetails" size="lg" v-if="row.detailsShowing"></b-icon-eye-slash>
                                        <b-button @click="addQuestionToCategoryModal(row.item)" variant="primary" size="sm" class="ml-2">
                                            Add Question
                                        </b-button>
                                        <b-button @click="editCategoryModal(row.item)" variant="primary" size="sm" class="ml-2">
                                            Edit
                                        </b-button>
                                        <b-button @click="deleteCategoryModal(row.item)" variant="red" size="sm" class="ml-2">
                                            Remove
                                        </b-button> 
                                    </b-row>
                                </template>
                                <template #row-details="row">
                                    <b-card class="questions-card">
                                        <table class="table table-striped">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th></th>
                                                    <th scope="col" v-for="(item, index) in tableData.questionsTableColumns" :key="index">{{item.label}}</th>
                                                </tr>
                                            </thead>
                                            <draggable v-model="row.item.questions" tag="tbody" item-key="name">
                                                <template>
                                                    <tr v-for="(el, index) in row.item.questions" :key="index">
                                                        <td scope="row" class="text-center"><b-icon-arrows-move></b-icon-arrows-move></td>
                                                        <td scope="row" class="td-col-55">{{ el.question }}</td>
                                                        <td scope="row" class="td-col-5">{{ el.answerType }}</td>
                                                        <td scope="row" class="td-col-10">
                                                            <b-badge :variant="el.imageFile === null ? 'red' : 'green'">{{el.imageFile === null ? 'No' : 'Yes'}}</b-badge>
                                                            <b-icon-eye @click="openModalImage(el.imageFile)" size="lg" v-if="el.imageFile !== null" class="ml-2"></b-icon-eye>
                                                        </td>
                                                        <td scope="row" class="text-right">
                                                            <b-button @click="openQuestionToCategoryModal(el)" variant="primary" size="sm" class="ml-2">
                                                                Edit
                                                            </b-button>
                                                            <b-button @click="deleteQuestionModal(el)" variant="red" size="sm" class="ml-2">
                                                                Remove
                                                            </b-button> 
                                                        </td>
                                                    </tr>
                                                </template>
                                            </draggable>
                                        </table>
                                    </b-card>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="red" squared class="mr-2" @click="backToPrevious()">Back</b-button>
                            <b-button variant="primary" squared @click="saveChecklist()">
                                <b-spinner small v-if="state === 'loading'"></b-spinner>Save</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-modal id="show-image-modal" hide-footer no-header @close="closeCategoryModal()">
            <b-row>
                <b-col>
                    <img v-if="modalImage.base64Data !== null" :src="returnFileObject(modalImage)" alt="" 
                        class="modal-images">
                    <p class="text-center" v-if="modalImage.base64Data === null" >Could not load image</p>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal id="category-modal" hide-footer size="xl" @close="closeCategoryModal()">
            <template #modal-header="{}">
                <h5>Category</h5>
            </template>
            <b-row>
                <b-col cols="12">
                    <label>Category Name</label>
                    <b-form-input v-model="selectedCategory.description"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <div class="material-box">
                        <label class="material-label">Category Image</label>
                        <b-form-file
                            accept="image/*"
                            v-model="selectedCategory.imageFile"
                            @change="filesUploaded"
                            placeholder="Choose a file..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-right mt-3">
                    <b-button variant="red" @click="closeCategoryModal" squared class="mr-2">Cancel</b-button>
                    <b-button variant="primary" @click="saveCategory()" squared>Save</b-button>
                </b-col>
            </b-row>
        </b-modal>
        
        <b-modal id="question-modal" hide-footer size="xl" @close="closeCategoryModal()">
            <b-row>
              <b-col cols="12"  v-for="(item, index) in messages" :key ="index">
                <b-alert show dismissible variant="warning">{{item}}</b-alert>
              </b-col>
            </b-row>
            <b-row>
                <b-col cols="8">
                    <label>Question</label>
                    <b-form-input v-model="selectedQuestion.question"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Answer Type</label>
                    <b-form-select v-model="selectedQuestion.answerType" :disabled="state === 'loading'" @change="changeAcceptableAnswers()">
                        <b-form-select-option v-for="(item, index) in answerTypes" :key="index" :value="item">{{item}}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <div class="material-box">
                        <label class="material-label">Question Image</label>
                        <b-form-file
                            accept="image/*"
                            v-model="selectedQuestion.imageFile"
                            @change="questionsFilesUploaded"
                            placeholder="Choose a file..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="selectedQuestion.answerType === 'MultiSelect' || selectedQuestion.answerType === 'RadioButton' || selectedQuestion.answerType === 'SingleSelect'">
                <b-col cols="12">
                    <label>Acceptable Answer</label>
                    <b-input-group>
                        <b-form-input v-model="newAnswer" :disabled="selectedQuestion.availableAnswers.length >= 6"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm" text="Button" variant="primary" @click="addAnswerToQuestion()" :disabled="selectedQuestion.availableAnswers.length >= 6">Add Answer</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row v-if="selectedQuestion.answerType === 'MultiSelect' || selectedQuestion.answerType === 'RadioButton' || selectedQuestion.answerType === 'SingleSelect'">
                <b-col cols="12">
                    <p class="text-center">Answers marked as an exception will ask the user for clarification when used as an answer</p>
                    <b-table striped hover :items="answersTableData.dataSource" :fields="answersTableData.tableColumns" 
                        :busy="answersTableData.isLoading" sort-icon-left>
                        <template #cell(exception)="row">
                            <b-row align-v="center" align-h="start">
                                <b-form-checkbox
                                    v-model="row.item.isException"
                                    value="true"
                                    unchecked-value="false">
                                </b-form-checkbox>
                            </b-row>
                        </template>
                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="deleteAnswers(row.item)" variant="red" size="sm" class="ml-2">
                                    Remove
                                </b-button> 
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            
            <b-row v-if="selectedQuestion.answerType === 'MultiLevel'">
                <b-col cols="6">
                    <label>Answer</label>
                    <b-input-group>
                        <b-form-input v-model="newAnswerOption"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm" text="Button" variant="primary" @click="addOptionToQuestion()">Add</b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <br/>

                    <b-table striped hover :items="optionsTableData.dataSource" :fields="optionsTableData.tableColumns" 
                        :busy="optionsTableData.isLoading" @row-clicked="selectOption" sort-icon-left>
                        
                        <template #cell(exception)="row">
                            <b-row align-v="center" align-h="start">
                                <b-form-checkbox
                                    v-model="row.item.isException"
                                    value="true"
                                    unchecked-value="false">
                                </b-form-checkbox>
                            </b-row>
                        </template>
                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="deleteAnswers(row.item)" variant="red" size="sm" class="ml-2">
                                    Remove
                                </b-button> 
                            </b-row>
                        </template>
                    </b-table>
                </b-col>

                <b-col cols="6">
                    <label>Answer Options</label>
                    <b-input-group>
                        <b-form-input v-model="newOption"></b-form-input>
                        <b-input-group-append>
                            <b-button size="sm" text="Button" variant="primary" @click="addOptionToAnswer()">Add</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <br/>
                    <b-table striped hover :items="selectedAnswerOptionTableData.dataSource" :fields="selectedAnswerOptionTableData.tableColumns" 
                        :busy="selectedAnswerOptionTableData.isLoading" sort-icon-left>
                        
                        <template #cell(exception)="row">
                            <b-row align-v="center" align-h="start">
                                <b-form-checkbox
                                    v-model="row.item.isException"
                                    value="true"
                                    unchecked-value="false">
                                </b-form-checkbox>
                            </b-row>
                        </template>
                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="deleteAnswerOption(row.item)" variant="red" size="sm" class="ml-2">
                                    Remove
                                </b-button> 
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>

            <b-row class="mt-4">
                <b-col>
                    <b-form-checkbox
                        v-model="selectedQuestion.required"
                        value="true"
                        unchecked-value="false"
                        >Required
                    </b-form-checkbox>
                    
                </b-col>
                <b-col class="text-right">
                    <b-button variant="primary" size="sm" squared @click="saveQuestion()">Save</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="category-delete-modal" hide-footer no-header @close="closeDeleteCategoryModal()">
            <b-row>
                <b-col cols="12" class="text-center">
                    Are you sure you want to delete this category?
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-center">
                    <b-button variant="red" size="sm" class="mr-2" squared @click="closeDeleteCategoryModal()">No</b-button>
                    <b-button variant="primary" size="sm" squared @click="completeDeleteCategory()">Yes</b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal id="question-delete-modal" hide-footer no-header @close="closeDeleteQuestionModal()">
            <b-row>
                <b-col cols="12" class="text-center">
                    Are you sure you want to delete this question?
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col class="text-center">
                    <b-button variant="red" size="sm" class="mr-2" squared @click="closeDeleteQuestionModal()">No</b-button>
                    <b-button variant="primary" size="sm" squared @click="completeDeleteQuestion()">Yes</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
    data: () => ({
        state: 'initializing',
        selectedAnswerOption: null,
        answerTypes: [],
        newAnswer: null,
        checklistTypes: [],
        messages: [],
        modalImage: {
            base64Data: null
        },
        newAnswerOption: null,
        selectedQuestion: {
            answerType: null,
            imageFile: null
        },
        selectedCategory: {
            checkListId: null,
            checkListCategoryID: 0,
            description: null,
            imageFile:null
        },
        newOption: null,
        selectedAnswerOptionTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: false,
            tableColumns: [
                {
                    label: 'Exception',
                    key: 'exception',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: 'Answer',
                    key: 'acceptableAnswer',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'td-col-20'
                }
            ]
        },
        optionsTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: false,
            tableColumns: [
                {
                    label: 'Exception',
                    key: 'exception',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: 'Answer',
                    key: 'acceptableAnswer',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'td-col-20'
                }
            ]
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: 'Image Included',
                    key: 'imageFile',
                    sortable: false,
                    tdClass: 'td-col-20'
                },
                {
                    label: 'Question Count',
                    key: 'questions',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'td-col-20'
                }
            ],
            questionsTableColumns:[
                {
                    label: 'Description',
                    key: 'question',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: 'Answer Type',
                    key: 'answerType',
                    sortable: true,
                    tdClass: 'td-col-20'
                },
                {
                    label: 'Image Included',
                    key: 'imageFile',
                    sortable: false,
                    tdClass: 'td-col-20'
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'td-col-20'
                }
            ]
        },

        answersTableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Exception',
                    key: 'exception',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Answer',
                    key: 'acceptableAnswer',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        }
    }),
    components: {
      draggable
    },
    created(){
        this.setBreadcrumb([
            {
                text: 'Checklist'
            },
            {
                text: 'Manage',
                active: true
            },
        ])
        if (this.selectedChecklist === null) {
            this.$router.back()            
        }
        if (this.selectedChecklist.checkListId !== 0) {
            
            this.getChecklistTypes()
            .then((res) => {
                this.checklistTypes = res.data
                
                this.getChecklistInformation()
                .then(() =>{
                    this.state = 'show'
                    this.tableData.dataSource = this.selectedChecklist.categories
                    this.tableData.isLoading = false
                })
            })
            .catch(() => {
                this.state = 'show'
            })

            this.getQuestionAnswerTypes()
            .then((res) => {
                this.answerTypes = res.data.result
            })
        }
        else{
            this.state = 'show'
            this.tableData.isLoading = false
        }
    },
    methods:{
        ...mapActions(['getChecklistInformation', 'updateChecklist', 'updateCategory', 'getQuestionAnswerTypes', 
            'deleteChecklist', 'updateQuestion', 'deleteCategory', 'getChecklistTypes']),
        ...mapActions('notificationService', ['addSuccessMessage']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        addOptionToQuestion(){
            this.selectedQuestion.availableAnswers.push({
                acceptableAnswer: this.newAnswerOption,
                isException: false, 
                multiLevelAcceptableAnswers: []
            })
            this.selectedAnswerOption = this.selectedQuestion.availableAnswers[this.selectedQuestion.availableAnswers.length-1]
            this.selectedAnswerOptionTableData.dataSource = this.selectedAnswerOption.multiLevelAcceptableAnswers
            this.newAnswerOption = null

        },
        addOptionToAnswer(){
            this.selectedAnswerOption.multiLevelAcceptableAnswers.push({
                acceptableAnswer: this.newOption,
                isException: false
            })
            this.newOption = null
        },
        selectOption(rowItem){
            let itemIndex = this.selectedQuestion.availableAnswers.findIndex(x => rowItem === x)
            this.selectedAnswerOption = this.selectedQuestion.availableAnswers[itemIndex]
            this.selectedAnswerOptionTableData.dataSource = this.selectedAnswerOption.multiLevelAcceptableAnswers
        },
        deleteOption(rowItem){
            let itemIndex = this.selectedQuestion.availableAnswers.findIndex(x => rowItem === x)
            this.selectedQuestion.availableAnswers.splice(itemIndex, 1)
            
            this.selectedAnswerOption = this.selectedQuestion.availableAnswers[0]
            this.selectedAnswerOptionTableData.dataSource = this.selectedAnswerOption.multiLevelAcceptableAnswers
        },
        deleteAnswerOption(rowItem){
            let itemIndex = this.selectedAnswerOption.multiLevelAcceptableAnswers.findIndex(x => rowItem === x)
            this.selectedAnswerOption.multiLevelAcceptableAnswers.splice(itemIndex, 1)
        },
        deleteAnswers(rowItem){
            if (rowItem.checkListAcceptableAnswerId !== 0) {
                let item = this.selectedQuestion.availableAnswers.findIndex(element => element.checkListAcceptableAnswerId === rowItem.checkListAcceptableAnswerId)
                this.selectedQuestion.availableAnswers.splice(item, 1)
            } 
            else {
                let item = this.selectedQuestion.availableAnswers.findIndex(element => element.acceptableAnswer === rowItem.acceptableAnswer)
                this.selectedQuestion.availableAnswers.splice(item, 1)
            }
        },
        changeAcceptableAnswers(){
            if (this.selectedQuestion.answerType === 'Text') {
                this.selectedQuestion.availableAnswers = []
                this.answersTableData.dataSource = this.selectedQuestion.availableAnswers
            }
            
            if (this.selectedQuestion.answerType === 'MultiLevel') {
                this.selectedAnswerOption = null
                this.selectedQuestion.availableAnswers = []
                this.optionsTableData.dataSource = this.selectedQuestion.availableAnswers
            }
        },
        openModalImage(image){
            debugger
            this.modalImage = image
            this.$root.$emit('bv::show::modal', 'show-image-modal', '#btnShow')
        },
        returnFileObject(item){
            debugger
            let base64 = 'data:' + item.contentType +';base64,'+ this.cleanBase64DataURI(item.base64Data)
            return base64
        },        
        cleanBase64DataURI(base64URI) {
            // Find the last occurrence of ';base64,'
            const lastCommaIndex = base64URI.lastIndexOf(';base64,');
            
            // If the last occurrence is found, extract the Base64 data after it
            if (lastCommaIndex !== -1) {
                return base64URI.slice(lastCommaIndex + 8); // Adding 8 to skip ';base64,'
            } else {
                // If the URI doesn't contain the expected format, return the original input
                return base64URI;
            }
        },
        addAnswerToQuestion(){
            this.selectedQuestion.availableAnswers.push({checkListAcceptableAnswerId: 0, acceptableAnswer: this.newAnswer})
            this.newAnswer = null
        },
        saveCategory(){
            this.selectedCategory.checkListId = this.selectedChecklist.checkListId
            this.$store.commit('setNewCategoryRequest', this.selectedCategory)
            this.updateCategory()
            .then((res) =>{
                this.closeCategoryModal()
                let response = res.data.result
                if (this.selectedCategory.checkListCategoryID === 0) {
                    if (this.selectedCategory.imageFile !== null) {
                        
                        let commaIndex = this.selectedCategory.imageFile.base64Data.indexOf(",")
                        response.imageFile = {
                            base64Data: this.selectedCategory.imageFile.base64Data.substr(commaIndex+1),
                            contentType: this.selectedCategory.imageFile.base64Data.substr(0, commaIndex),
                            fileName: this.selectedCategory.imageFile.fileName
                        }
                    }

                    this.selectedChecklist.categories.push(response)
                }
                else{
                    if (this.selectedCategory.imageFile !== null) {
                        let commaIndex = this.selectedCategory.imageFile.base64Data.indexOf(",")
                        if (commaIndex !== -1) {
                            
                            this.selectedCategory.imageFile = {
                                base64Data: this.selectedCategory.imageFile.base64Data.substr(commaIndex+1),
                                contentType: this.selectedCategory.imageFile.base64Data.substr(0, commaIndex),
                                fileName: this.selectedCategory.imageFile.fileName
                            }
                        }
                        
                    }
                }
                
                this.selectedCategory = {
                    checkListId: null,
                    checkListCategoryID: 0,
                    description: null,
                    imageFile: null
                }
            })
            .catch(() => {
                this.closeCategoryModal()
            })
        },
        saveQuestion(){
            this.messages = []
            if (this.selectedQuestion.question === null || this.selectedQuestion.question === '') {
                this.messages.push('You need to enter a question')
                return;
            }
            if (this.selectedQuestion.question.length > 8000) {
                this.messages.push('You have a limit of 8000 characters, please shorten your question')
                return;
            }
            if (this.selectedQuestion.answerType === null || this.selectedQuestion.answerType === '') {
                this.messages.push('You need to select an answer type')
                return;
            }
            this.selectedQuestion.checkListId = this.selectedChecklist.checkListId
            this.$store.commit('setQuestionForCategoryRequest', this.selectedQuestion)
            this.updateQuestion()
            .then((res) =>{
                if (this.selectedQuestion.questionId === 0) {
                    this.selectedQuestion.questionId = res.data
                    let item = this.selectedChecklist.categories.findIndex(element => element.checkListCategoryID === this.selectedQuestion.categoryId)
                    this.selectedChecklist.categories[item].questions.push(this.selectedQuestion)
                }
                this.closeQuestionModal()
                this.selectedQuestion = {
                    question : null,
                    answerType: null,
                    imageFile: null
                }
            })
            .catch(() => {
                this.closeQuestionModal()
            })
        },
        saveChecklist(){
            this.state = 'loading'
            for (let i = 0; i < this.selectedChecklist.categories.length; i++) {
                const category = this.selectedChecklist.categories[i];
                for (let j = 0; j < category.questions.length; j++) {
                    const question = category.questions[j];
                    question.sequence = j + 1
                }
            }
            this.updateChecklist()
            .then(() => {
                this.$router.back()
                this.state = 'show'
            })
            .catch(() => {
                this.state = 'show'
            })
        },
        openAddNewCategoryModal(){
            this.selectedCategory = {
                checkListId: null,
                checkListCategoryID: 0,
                description: null,
                imageFile: null
            }
            this.$root.$emit('bv::show::modal', 'category-modal', '#btnShow')
        },
        closeCategoryModal(){
            this.$root.$emit('bv::hide::modal', 'category-modal', '#btnShow')
        },
        addQuestionToCategoryModal(rowItem){
            let item = {
                active: true,
                answerType: null,
                availableAnswers: [],
                categoryId: rowItem.checkListCategoryID,
                checkListId: rowItem.checkListId,
                question: null,
                questionId: 0,
                required: true,
                imageFile: null
            }
            this.selectedQuestion = item
            this.answersTableData.dataSource = item.availableAnswers
            this.answersTableData.isLoading = false
            this.$root.$emit('bv::show::modal', 'question-modal', '#btnShow')
        },
        openQuestionToCategoryModal(rowItem){
            this.selectedQuestion = rowItem
            this.answersTableData.dataSource = rowItem.availableAnswers
            
            this.optionsTableData.dataSource = this.selectedQuestion.availableAnswers
            if (this.selectedQuestion.availableAnswers.length > 0) {
                this.selectedAnswerOption = this.selectedQuestion.availableAnswers[0]
                //console.log('openQuestionToCategoryModal', this.selectedQuestion)
                this.selectedAnswerOptionTableData.dataSource = this.selectedAnswerOption.multiLevelAcceptableAnswers
            }

            this.answersTableData.isLoading = false
            this.$root.$emit('bv::show::modal', 'question-modal', '#btnShow')
        },
        closeQuestionModal(){
            this.$root.$emit('bv::hide::modal', 'question-modal', '#btnShow')
        },
        editCategoryModal(rowItem){
            this.selectedCategory = rowItem
            this.$root.$emit('bv::show::modal', 'category-modal', '#btnShow')
        },
        deleteCategoryModal(rowItem){
            this.selectedCategory = rowItem
            this.$root.$emit('bv::show::modal', 'category-delete-modal', '#btnShow')
        },
        closeDeleteCategoryModal(){
            this.selectedCategory = {}
            this.$root.$emit('bv::hide::modal', 'category-delete-modal', '#btnShow')
        },
        completeDeleteCategory(){
            this.$store.commit('setDeleteCategoryRequest', this.selectedCategory)
            this.deleteCategory()
            .then(() => {
                let item = this.selectedChecklist.categories.findIndex(element => element.checkListCategoryID === this.selectedCategory.checkListCategoryID)
                this.selectedChecklist.categories.splice(item, 1)
                this.closeDeleteCategoryModal()
            })
            .catch(() => {
                this.closeDeleteCategoryModal()
            })
        },
        completeDeleteQuestion(){
            this.selectedQuestion.checkListId = this.selectedChecklist.checkListId
            this.selectedQuestion.active = false
            this.$store.commit('setQuestionForCategoryRequest', this.selectedQuestion)
            this.updateQuestion()
            .then(() =>{
                let item = this.selectedChecklist.categories.findIndex(element => element.checkListCategoryID === this.selectedQuestion.categoryId)
                let question = this.selectedChecklist.categories[item].questions.findIndex(element => element.questionId === this.selectedQuestion.questionId)
                this.selectedChecklist.categories[item].questions.splice(question, 1)

                this.selectedQuestion = null
                this.closeDeleteQuestionModal()
                this.addSuccessMessage('Question removed')
            })
            .catch(() => {
                this.closeDeleteQuestionModal()
            })
        },
        deleteQuestionModal(rowItem){
            this.selectedQuestion = rowItem
            this.$root.$emit('bv::show::modal', 'question-delete-modal', '#btnShow')
        },
        closeDeleteQuestionModal(){
            this.selectedQuestion = {}
            this.$root.$emit('bv::hide::modal', 'question-delete-modal', '#btnShow')
        },
        backToPrevious(){
            this.$router.back()
        },
        filesUploaded(e){
            const selectedDocument = e.target.files[0]
                if (selectedDocument.size > 20000000) {
                        this.errors.push({message: 'Document to big to be uploaded'})
                    return
                }
            this.createBase64File(selectedDocument)
            this.uploadFile = null
        },
        createBase64File(fileObject){
            debugger
            const reader = new FileReader()

            reader.onload = (e) => {
                this.file1 = e.target.result

                let request = {
                    fileName: fileObject.name,
                    base64Data: this.file1
                }

                this.selectedCategory.imageFile = request
            }

            reader.readAsDataURL(fileObject)
        },
        
        questionsFilesUploaded(e){
            const selectedDocument = e.target.files[0]
                if (selectedDocument.size > 20000000) {
                        this.errors.push({message: 'Document to big to be uploaded'})
                    return
                }
            this.createBase64FileQuestions(selectedDocument)
        },
        createBase64FileQuestions(fileObject){
            debugger
            const reader = new FileReader()

            reader.onload = (e) => {
                this.file1 = e.target.result

                let request = {
                    fileName: fileObject.name,
                    base64Data: this.file1,
                    contentType: fileObject.type
                }

                this.selectedQuestion.imageFile = request
            }

            reader.readAsDataURL(fileObject)
        },
        deleteCurrentChecklist(){
            this.state = 'loading'
            this.deleteChecklist()
            .then(() => {
                this.$router.back()
                this.state = 'show'
            })
            .catch(() => {
                this.state = 'show'
            })
        }
    },
    computed:{
        ...mapState(['selectedChecklist'])
    }
}
</script>